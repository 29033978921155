
import {defineComponent, onMounted} from "vue";
import AddProduct from "../components/AddProduct.vue";
import {setPageHeader, setPageHistory} from "@/core/helpers/toolbar";
import {useStore} from "vuex";

export default defineComponent({
    components: {
        AddProduct
    },
    setup() {
        const store = useStore()
        onMounted(() => {
            setPageHeader({
                title: "message.ADD message.PRODUCTS",
                actionButton: null,
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.CATALOG", link: "#"},
                    { name: "message.PRODUCTS", link: "/catalog/products"},
                    { name: "message.ADD message.PRODUCT",}
                ]
            });
            setPageHistory({});
            store.commit('SET_SEARCH_DATA', {});
        })
    }
});
